import React from "react"
import { createStore as reduxCreateStore, applyMiddleware } from "redux"
import { Provider } from "react-redux"
import { persistStore, persistReducer } from "redux-persist"
import storage from "redux-persist/lib/storage" // defaults to localStorage for web
import { PersistGate } from "redux-persist/integration/react"
import thunk from "redux-thunk"
import { composeWithDevTools } from "redux-devtools-extension"

import rootReducer from "./reducer"

const persistConfig = {
  key: "root",
  storage,
}

const persistedReducer = persistReducer(persistConfig, rootReducer)

const createStore = () => {
  let store = reduxCreateStore(persistedReducer, composeWithDevTools(applyMiddleware(thunk)))
  let persistor = persistStore(store)
  return { store, persistor }
}

const ReduxContainer = ({ element }) => {
  const { store, persistor } = createStore()
  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        {element}
      </PersistGate>
    </Provider>
  )
}

export default ReduxContainer
