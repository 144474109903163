const initialState = {}

export default (state = initialState, action) => {
  switch (action.type) {
    case "levelEvaluationToken/SET_LEVEL_EVALUATION_TOKEN":
      return {
        ...state,
        result: action.payload,
      }
    case "LOGOUT":
      return initialState

    default:
      return state
  }
}
