const initialState = {}

export default (state = initialState, action) => {
  switch (action.type) {
    case "question/SET_QUESTION":
      return {
        ...state,
        [action.levelEvaluationToken]: {
          ...state[action.levelEvaluationToken],
          result: action.payload,
        },
      }
    case "question/SET_LOADING":
      return {
        ...state,
        [action.levelEvaluationToken]: {
          ...state[action.levelEvaluationToken],
          loading: action.payload,
        },
      }
    case "question/SET_ERROR":
      return {
        ...state,
        [action.levelEvaluationToken]: {
          ...state[action.levelEvaluationToken],
          error: action.payload,
        },
      }
    case "LOGOUT":
      return initialState
    default:
      return state
  }
}
