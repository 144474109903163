const initialState = {}

export default (state = initialState, action) => {
  switch (action.type) {
    case "hrManagers/SET_PERSONS":
      return {
        ...state,
        [action.companyUUID]: {
          ...state[action.companyUUID],
          results: action.payload,
        },
      }
    case "hrManagers/SET_LOADING":
      return {
        ...state,
        [action.companyUUID]: {
          ...state[action.companyUUID],
          loading: action.payload,
        },
      }
    case "hrManagers/SET_ERROR":
      return {
        ...state,
        [action.companyUUID]: {
          ...state[action.companyUUID],
          error: action.payload,
        },
      }
    case "LOGOUT":
      return initialState
    default:
      return state
  }
}
