import axios from "axios"
import { Person, LevelEvaluation, EvaluationQuestion, Company, HRManager, UUID, LoginResponse, StaticString, Invitation } from "./types"
import ApiError from "./ApiError"
import { v4 } from "uuid"
import * as qs from "qs"
import * as cookies from "js-cookie"

export const TOKEN_KEY = "etolo_session_token"

const EtoloAPI = axios.create({
  baseURL: "https://api-v1.e-tolo.eu/",
  timeout: 60000,
})

EtoloAPI.interceptors.request.use(config => {
  const token = cookies.get(TOKEN_KEY)
  if (token) {
    config.headers.Authorization = `Bearer ${token}`
  }
  return config
})

EtoloAPI.interceptors.response.use(
  response => {
    if (response.data.errorcode) {
      throw new ApiError(response.data)
    }
    return response.data
  },
  error => {
    if (error.response) {
      throw new ApiError(error.response.data)
    }
    throw error
  }
)

/********************************/
/* AUTHENTICATION
/********************************/

export const login = async (username: string, password: string): Promise<LoginResponse> => {
  const response = (await EtoloAPI.post(`/?giveme=login`, qs.stringify({ login: username, password }))) as LoginResponse
  return response
}

export const logout = async (): Promise<{}> => {
  return await EtoloAPI.get(`/?giveme=logout`)
}

export const requestPasswordResetLink = async (email: string): Promise<{}> => {
  return await EtoloAPI.post(`/?giveme=password-reset-request`, qs.stringify({ login: email }))
}

export const resetPassword = async (token: string, password: string): Promise<{}> => {
  return await EtoloAPI.post(`/?giveme=password-reset`, qs.stringify({ token, password }))
}

/********************************/
/* PERSON
/********************************/

export const getPerson = async (personUUID: UUID): Promise<Person> => {
  const person = (await EtoloAPI.get(`/?giveme=one-person&uuid=${personUUID}`)) as Person
  return person
}

export const getPersonsByEmail = async (email: string, companyUUID: UUID): Promise<Person[]> => {
  const personList = (await EtoloAPI.get(`/?giveme=list-persons&companyuuid=${companyUUID}&email=${email}`)) as Person[]
  return personList
}

export const createPerson = async (person: Person): Promise<Person> => {
  return await EtoloAPI.put(`/?giveme=one-person&uuid=${person.uuid}`, person)
}

export const patchPerson = async (person: Person): Promise<Person> => {
  return await EtoloAPI.patch(`/?giveme=one-person&uuid=${person.uuid}`, person)
}

export const deletePerson = async (personUUID: UUID): Promise<Person> => {
  return await EtoloAPI.delete(`/?giveme=one-person&uuid=${personUUID}`)
}

export const getPersonList = async (companyUUID: UUID): Promise<Person[]> => {
  const personList = (await EtoloAPI.get(`/?giveme=list-persons&companyuuid=${companyUUID}`)) as Person[]
  return personList
}

/********************************/
/* HR MANAGER
/********************************/

export const getHRManagerList = async (companyUUID: UUID): Promise<HRManager[]> => {
  const managerList = (await EtoloAPI.get(`/?giveme=list-hrmanagers&companyuuid=${companyUUID}`)) as HRManager[]
  return managerList
}

/********************************/
/* COMPANY
/********************************/

export const getCompany = async (companyUUID: UUID): Promise<Company> => {
  const company = (await EtoloAPI.get(`/?giveme=one-company&uuid=${companyUUID}`)) as Company
  return company
}

export const createCompany = async (company: Company): Promise<Company> => {
  return await EtoloAPI.put(`/?giveme=one-company&uuid=${company.uuid}`, company)
}

export const patchCompany = async (company: Company): Promise<Company> => {
  return await EtoloAPI.patch(`/?giveme=one-company&uuid=${company.uuid}`, company)
}

export const deleteCompany = async (companyUUID: UUID): Promise<Company> => {
  return await EtoloAPI.delete(`/?giveme=one-company&uuid=${companyUUID}`)
}

export const getCompanyList = async (): Promise<Company[]> => {
  const companyList = (await EtoloAPI.get(`/?giveme=list-companies`)) as Company[]
  return companyList
}

/********************************/
/* LEVEL EVALUATION
/********************************/

export const getLevelEvaluationByUUID = async (token: UUID): Promise<LevelEvaluation> => {
  const levelEvaluation = (await EtoloAPI.get(`/?giveme=one-levelevaluation&uuid=${token}`)) as LevelEvaluation
  return levelEvaluation
}

export const getLevelEvaluationByToken = async (token: UUID): Promise<LevelEvaluation> => {
  const levelEvaluation = (await EtoloAPI.get(`/?giveme=one-levelevaluation&token=${token}`)) as LevelEvaluation
  return levelEvaluation
}

export const createLevelEvaluation = async (levelEvaluation: LevelEvaluation): Promise<LevelEvaluation> => {
  return await EtoloAPI.put(`/?giveme=one-levelevaluation&uuid${levelEvaluation.uuid}`, levelEvaluation)
}

export const createListLevelEvaluations = async (levelEvaluations: LevelEvaluation[], companyUUID: string): Promise<void> => {
  return await EtoloAPI.put(`/?giveme=list-levelevaluations&companyuuid=${companyUUID}`, levelEvaluations)
}

export const updateLevelEvaluation = async (levelEvaluation: LevelEvaluation): Promise<LevelEvaluation> => {
  return await EtoloAPI.patch(`/?giveme=one-levelevaluation&uuid${levelEvaluation.uuid}`, levelEvaluation)
}

export const deleteLevelEvaluation = async (token: UUID): Promise<LevelEvaluation> => {
  return await EtoloAPI.delete(`/?giveme=one-levelevaluation&uuid=${token}`)
}

export const getLevelEvaluationList = async (companyUUID: UUID): Promise<LevelEvaluation[]> => {
  const levelEvaluationList = (await EtoloAPI.get(`/?giveme=list-levelevaluations&companyuuid=${companyUUID}`)) as LevelEvaluation[]
  return levelEvaluationList
}

export const getEvaluationQuestion = async (token: UUID): Promise<EvaluationQuestion> => {
  const evaluationQuestion = (await EtoloAPI.get(`/?giveme=one-question&token=${token}`)) as EvaluationQuestion
  return evaluationQuestion
}

export const provideAnswer = async (evaluationQuestionUUID: UUID, answerText: string, timeToAnswer: number): Promise<any> => {
  const nextQuestion = await EtoloAPI.patch(`?giveme=one-answer&questionuuid=${evaluationQuestionUUID}`, {
    questionuuid: evaluationQuestionUUID,
    answer: answerText,
    timetoanswer: timeToAnswer,
  })

  return nextQuestion
}
/********************************/
/* INVITATION
/********************************/
export const getInvitationByToken = async (token: string): Promise<Invitation> => {
  const invitation = (await EtoloAPI.get(`/?giveme=one-invitation&token=${token}`)) as Invitation
  return invitation
}

export const getInvitationByUUID = async (uuid: UUID): Promise<Invitation> => {
  const invitation = (await EtoloAPI.get(`/?giveme=one-invitation&uuid=${uuid}`)) as Invitation
  return invitation
}

export const getInvitationList = async (uuid: UUID): Promise<Invitation[]> => {
  const invitations = (await EtoloAPI.get(`/?giveme=list-invitations&companyuuid=${uuid}`)) as Invitation[]
  return invitations
}
export const createInvitation = async (uuid: UUID, invitation: Invitation): Promise<Invitation> => {
  return await EtoloAPI.put(`/?giveme=one-invitation&uuid=${uuid}`, invitation)
}

export const patchInvitation = async (uuid: UUID, invitation: Invitation): Promise<Invitation> => {
  return await EtoloAPI.patch(`/?giveme=one-invitation&uuid=${uuid}`, invitation)
}

export const deleteInvitation = async (uuid: UUID): Promise<Invitation> => {
  return await EtoloAPI.delete(`/?giveme=one-invitation&uuid=${uuid}`)
}

export const acceptInvitation = async (token: string, person: Person): Promise<void> => {
  // @ts-ignore
  return await EtoloAPI.put(`/?giveme=accept-invitation&token=${token}`, { uuid: v4(), ...person })
}

/********************************/
/* OTHER
/********************************/

export const getStaticStrings = async (language: string, companyUUID: UUID): Promise<StaticString[]> => {
  return await EtoloAPI.get(`/?giveme=static-strings&lang=${language}&companyuuid=${companyUUID}`)
}

export const createStaticStrings = async (language: string, uuid: UUID, staticStrings: StaticString[]): Promise<StaticString[]> => {
  return await EtoloAPI.put(`/?giveme=static-strings&lang=${language}&uuid=${uuid}`, staticStrings)
}

export default {
  login,
  logout,
  getPerson,
  getPersonList,
  getCompany,
  getCompanyList,
  getEvaluationQuestion,
  getLevelEvaluationByUUID,
  getLevelEvaluationByToken,
  getLevelEvaluationList,
  getHRManagerList,
  updateLevelEvaluation,
  createLevelEvaluation,
  createPerson,
  deleteLevelEvaluation,
  deletePerson,
  patchPerson,
  createCompany,
  patchCompany,
  deleteCompany,
  getStaticStrings,
  requestPasswordResetLink,
  resetPassword,
  TOKEN_KEY,
}
