const initialState = { results: [], loading: false, error: undefined }

export default (state = initialState, action) => {
  switch (action.type) {
    case "companies/SET_COMPANIES":
      return { ...state, results: action.payload }
    case "companies/SET_LOADING":
      return { ...state, loading: action.payload }
    case "companies/SET_ERROR":
      return { ...state, error: action.payload }
    case "LOGOUT":
      return initialState
    default:
      return state
  }
}
