import { TOKEN_KEY } from "src/api"
import cookies from "js-cookie"

const initialState = {
  user: undefined,
  sessionToken: undefined,
  company: undefined,
}

export default (state = initialState, action) => {
  switch (action.type) {
    case "account/SET_USER":
      return { ...state, user: action.payload }
    case "account/SET_SESSION_TOKEN":
      cookies.set(TOKEN_KEY, action.payload)
      return { ...state, sessionToken: action.payload }
    case "account/SET_COMPANY":
      return { ...state, company: action.payload }
    case "LOGOUT":
      return initialState
    default:
      return state
  }
}
