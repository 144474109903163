import { defineMessages } from "gatsby-plugin-intl"

export const defaultMessages = defineMessages({
  questionInstructionTextOne: "Fill in the gap and move to the next question.",
  questionInstructionTextTwo: "Find the opposite/contrary of this term and move to the next question.",
  questionInstructionTextThree: "Give a synonym and move to the next question.",
  questionInstructionTextFour: "Answer the question and move to the next question.",
  questionInstructionTextFive: "Which sentence is correct?",
  questionInstructionTextSix: "This sentence means more or less the same as:",
  questionInstructionTextSeven: "The word in colour means more or less the same as:",
  questionInstructionTextEight: "The word in colour is the opposite/contrary of:",
  questionInstructionTextNine: "This term means more or less the same as:",
  questionInstructionTextTen: "This expression means more or less the same as:",
  questionInstructionTextEleve: "These words mean more or less the same as:",
  questionInstructionTextTwelve: "This sentence is the opposite/contrary of:",
  questionInstructionTextFourteen: "The words in colour mean the opposite/contrary of:",
  questionInstructionTextFifteen: "This expression is the opposite/contrary of:",
  questionInstructionTextSixteen: "What does this image show?",
  questionInstructionTextSeventeen: "Choose from the following options to find what mirrors the audio sequence.",
  questionInstructionTextEighteen: "Complete the following phrases after listening to the audio.",
  questionInstructionTextNineteen: "Which image represents the indicated word(s)",
})
