import { defaultMessages } from "../../utils/defaultStaticStrings"

const initialState = { results: defaultMessages, loading: false, error: undefined, language: "en" }

export default (state = initialState, action) => {
  switch (action.type) {
    case "strings/SET_STRINGS":
      return {
        [action.companyUUID]: {
          ...state[action.companyUUID],
          results: action.payload,
        },
      }
    case "strings/SET_LOADING":
      return {
        [action.companyUUID]: {
          ...state[action.companyUUID],
          loading: action.payload,
        },
      }
    case "strings/SET_ERROR":
      return {
        [action.companyUUID]: {
          ...state[action.companyUUID],
          error: action.payload,
        },
      }
    case "strings/SET_UI_LANGUAGE":
      return { ...state, language: action.payload }
    case "LOGOUT":
      return initialState
    default:
      return state
  }
}
