/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it
import "./src/styles/global.css"

// gatsby-browser.js
export const onRouteUpdate = () => {
  window.locations = window.locations || [document.referrer]
  window.locations.push(window.location.href)
  window.previousPath = window.locations[window.locations.length - 2]
}
export { default as wrapRootElement } from "./src/store"
