const initialState = {}

export default (state = initialState, action) => {
  switch (action.type) {
    case "invitationByToken/SET_INVITATION":
      return {
        ...state,
        [action.invitationToken]: {
          ...state[action.invitationToken],
          result: action.payload,
          error: undefined,
        },
      }
    case "invitationByToken/SET_LOADING":
      return {
        ...state,
        [action.invitationToken]: {
          ...state[action.invitationToken],
          loading: action.payload,
          error: undefined,
        },
      }
    case "invitationByToken/SET_ERROR":
      return {
        ...state,
        [action.invitationToken]: {
          ...state[action.invitationToken],
          error: action.payload,
        },
      }
    case "LOGOUT":
      return initialState
    default:
      return state
  }
}
