export default class ApiError {
  status: number
  code: number
  message: string
  details: Array<any>

  constructor(error: any) {
    this.status = error.httpstatus || -1
    this.code = error.errorcode || -1
    this.message = error.message || "unknown error"
    this.details = error.details || []
  }
}
