import { combineReducers } from "redux"
import account from "./account/reducer"
import companies from "./companies/reducer"
import persons from "./persons/reducer"
import levelEvaluations from "./levelEvaluations/reducer"
import levelEvaluation from "./levelEvaluation/reducer"
import hrManagers from "./hrManagers/reducer"
import company from "./company/reducer"
import person from "./person/reducer"
import question from "./question/reducer"
import levelEvaluationByToken from "./levelEvaluationByToken/reducer"
import invitation from "./invitation/reducer"
import invitationByToken from "./invitationByToken/reducer"
import invitations from "./invitations/reducer"
import strings from "./strings/reducer"
import levelEvaluationToken from "./levelEvaluationToken/reducer"

export default combineReducers({
  account,
  company,
  companies,
  persons,
  levelEvaluations,
  hrManagers,
  person,
  question,
  levelEvaluation,
  levelEvaluationByToken,
  invitation,
  invitationByToken,
  invitations,
  strings,
  levelEvaluationToken,
})
