// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-admin-index-js": () => import("./../../../src/pages/admin/index.js" /* webpackChunkName: "component---src-pages-admin-index-js" */),
  "component---src-pages-admin-invitations-js": () => import("./../../../src/pages/admin/invitations.js" /* webpackChunkName: "component---src-pages-admin-invitations-js" */),
  "component---src-pages-admin-invites-js": () => import("./../../../src/pages/admin/invites.js" /* webpackChunkName: "component---src-pages-admin-invites-js" */),
  "component---src-pages-admin-login-js": () => import("./../../../src/pages/admin/login.js" /* webpackChunkName: "component---src-pages-admin-login-js" */),
  "component---src-pages-admin-settings-js": () => import("./../../../src/pages/admin/settings.js" /* webpackChunkName: "component---src-pages-admin-settings-js" */),
  "component---src-pages-atolo-admins-js": () => import("./../../../src/pages/atolo/admins.js" /* webpackChunkName: "component---src-pages-atolo-admins-js" */),
  "component---src-pages-atolo-create-client-js": () => import("./../../../src/pages/atolo/create-client.js" /* webpackChunkName: "component---src-pages-atolo-create-client-js" */),
  "component---src-pages-atolo-index-js": () => import("./../../../src/pages/atolo/index.js" /* webpackChunkName: "component---src-pages-atolo-index-js" */),
  "component---src-pages-atolo-login-js": () => import("./../../../src/pages/atolo/login.js" /* webpackChunkName: "component---src-pages-atolo-login-js" */),
  "component---src-pages-audio-js": () => import("./../../../src/pages/audio.js" /* webpackChunkName: "component---src-pages-audio-js" */),
  "component---src-pages-certificate-js": () => import("./../../../src/pages/certificate.js" /* webpackChunkName: "component---src-pages-certificate-js" */),
  "component---src-pages-confirm-email-js": () => import("./../../../src/pages/confirm-email.js" /* webpackChunkName: "component---src-pages-confirm-email-js" */),
  "component---src-pages-disclaimer-js": () => import("./../../../src/pages/disclaimer.js" /* webpackChunkName: "component---src-pages-disclaimer-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-introduction-js": () => import("./../../../src/pages/introduction.js" /* webpackChunkName: "component---src-pages-introduction-js" */),
  "component---src-pages-invitation-js": () => import("./../../../src/pages/invitation.js" /* webpackChunkName: "component---src-pages-invitation-js" */),
  "component---src-pages-level-evaluation-js": () => import("./../../../src/pages/level-evaluation.js" /* webpackChunkName: "component---src-pages-level-evaluation-js" */),
  "component---src-pages-pdf-report-js": () => import("./../../../src/pages/pdf-report.js" /* webpackChunkName: "component---src-pages-pdf-report-js" */),
  "component---src-pages-reset-password-js": () => import("./../../../src/pages/reset-password.js" /* webpackChunkName: "component---src-pages-reset-password-js" */),
  "component---src-pages-start-js": () => import("./../../../src/pages/start.js" /* webpackChunkName: "component---src-pages-start-js" */)
}

